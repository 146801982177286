.mainContainer {
    display: flex;
    justify-content: center;
}

.workProjects {
    display: block;
}

.personalProjects {
    display: block;
}

.projectContainer {
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 60%;
    margin-bottom: 30px;
}

@media screen and (min-width: 850px) {
    <<<<<<< HEAD .projectContainer {
        width: 60%
    }

    .workProjects,
    .personalProjects {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .workProjects .workProjectCard,
    .personalProjects .workProjectCard {
        width: 80%;
    }
}

@media screen and (max-width: 500px) {
    .projectContainer {
        width: 85%;
    }
}