* {
    font-family: 'Barlow', sans-serif;
}

.home {
    display: flex;
    width: 60%;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.myWorkButton {
    background-color: var(--blue);
    font-family: 'Barlow', sans-serif;
    font-size: 12pt;
    color: whitesmoke;
    display: center;
    border-style: none;
    border-radius: 5px;
    padding: 10px 30px 10px 30px;
    transition: 0.5s;
}

.myWorkButton:hover {
    background-color: var(--blueHover);
    color: var(--light)
}

.home span {
    font-weight: 600;
    color: var(--darkGray);
    font-size: 76pt;
    margin: 0;
    padding: 0;
}

.home span:nth-child(1) {
    animation: animate 1s;
    animation-timing-function: ease-in-out;
}

.home span:nth-child(2) {
    animation: animate 2s;
}

.home span:nth-child(3) {
    animation: animate 2s;
}

.home span:nth-child(4) {
    animation: animate 3s;
}

.home span:nth-child(5) {
    animation: animate 2s;
}

.home span:nth-child(6) {
    animation: animate 1s;
}

.home span:nth-child(7) {
    animation: animate 1s;
}

.home span:nth-child(8) {
    animation: animate 3s;
}

@keyframes animate {
    0% {
        transform: translateX(-1000px);
    }

    100% {
        transform: translateY(0px)
    }
}

.home h2 {
    color: var(--darkGray);
    font-weight: 500;
    font-size: 32pt;
}

.socials {
    display: flex;
}

.socials a {
    color: var(--blueHover);
    background-color: var(--lowContrast);
    border-radius: 50%;
    ;
    margin: 0 15px 20px 0;
    padding: 10px;
    text-decoration: none;
    transition: ease-in-out 0.3s;
}

.socials a:hover {
    background-color: var(--blueHover);
    color: var(--lowContrast);
}

.bg {
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 1000px) {
    .home span {
        font-size: 62px;
    }

    .bg {
        background-position: center;
        background-size: 60vh;
    }

    .home h2 {
        font-size: 24pt;
    }

    .homeBottom {
        display: block;
    }
}

@media screen and (max-width: 500px) {
    .bg {
        background-position: center;
        background-size: 60vh;
    }

    .home span {
        font-size: 54px;
    }

    .bg {
        background-position: center;
        background-size: 60vh;
    }

    .home h2 {
        font-size: 24pt;
    }

    .home {
        align-items: flex-start;
        width: 85%;
    }
}