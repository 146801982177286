.workDetailContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.projectDetail {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.imageGallery img {
    margin: 25px 0 15px 0;
    object-fit: contain;
    height: 250px;
    width: fit-content;
}

.imageGallery img:hover,
.imageGallery img:active {
    pointer-events: none;
}

.imageGallery {
    display: flex;
    align-items: center;
    padding: 0 20px;
    overflow-x: scroll;
}

.mainLink {
    color: var(--blueHover);
    margin-top: 30px;
    text-decoration: none;
    font-size: 30pt;
    font-weight: 600;
    transition: ease-in-out 0.18s;
    width: 60%;
}

.projectDetail span {
    transition: ease-in-out 0.2s;
}

.mainLink:hover,
.secondLink:hover {
    color: var(--blue);
}

.mainLink:hover>span {
    letter-spacing: 2px;
    margin-left: 8px;
}

.projectDetail h2 {
    text-align: center;
    font-size: 12pt;
    color: var(--blueHover);
    margin: 25px 0 0 0;
    width: 60%;
}

.projectDetail p {
    margin-bottom: 75px;
    width: 60%;
}

.secondLink {
    margin-top: 15px;
    border-style: none;
    color: var(--blueHover);
    font-weight: 600;
    transition: ease-in-out 0.18s;
    width: 60%;
}

.notFound {
    display: flex;
    justify-content: center;
}

.notFound div {
    width: 60%;
}

.notFound a {
    text-decoration: none;
    border: solid 2px var(--blue);
    border-radius: 3px;
}

@media screen and (max-width:500px) {
    .mainLink {
        font-size: 20pt;
    }

    .mainLink,
    .projectDetail h2,
    .projectDetail p,
    .secondLink,
    .notFound div {
        width: 85%;
    }
}