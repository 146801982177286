:root {
  --blue: #4151CA;
  --blueHover: #161b4b;
  --light: #c4c4c4;
  --lightGray: #f3f3f3;
  --lowContrast: #ddd5d5bb;
  --red: #BF0E2B;
  --orange: #FF5759;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* custom scrollbar  */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  color: var(--blue);
  transition: ease-in-out 0.2s;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  transition: ease-in-out 0.2s;
  background: #e6e6e6;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  transition: ease-in-out 0.2s;
  background: var(--blue);
}

::-webkit-scrollbar-button:start {
  display: block;
}

::-webkit-scrollbar-button:end {
  display: block;
}