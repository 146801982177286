.formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-bottom: 20px;
    min-height: 70vh;
}

.formHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.formHead img {
    width: 35px;
}

.formSidebar {
    width: 35%;
    max-width: 200px;
    background-color: var(--blueHover);
    border-radius: 8px 0 0 8px;
}

.linkContainer {
    display: flex;
    justify-content: left;
    padding: 100px 10px;
}

.links {
    padding: 5px;
    width: 100%;
    width: fit-content;
}

.linksList {
    display: flex;
    flex-direction: column;
}

.linksList div {
    display: flex;
}

.links a,
.links a:visited {
    padding: 8px;
    margin: 12px;
    justify-content: space-between;
    font-weight: bold;
    font-size: 14pt;
    justify-content: space-around;
    color: whitesmoke;
    background-color: var(--blue);
    border-radius: 20px;
    transition: ease-in-out 0.3s;
    text-decoration: none;
}

.links p {
    color: white;
    font-size: 12pt;
}

.links a:hover,
.links p:hover {
    background-color: var(--blueHover);
}

.form {
    display: flex;
    background-color: var(--lowContrast);
    margin: 10px;
    width: 60vw;
    max-width: 800px;
    height: fit-content;
    border-radius: 8px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.formMain {
    width: 70%;
    padding: 20px;
}

.form h1 {
    color: var(--darkGray);
    font-size: 24pt;
    margin: 5px 0 0 0;
}

input,
textarea {
    background-color: white;
    margin: 5px 0 12px 0;
    padding: 5px;
    border-radius: 3px;
    border-style: none;
    color: var(--darkGray);
    width: 100%;
    box-sizing: border-box
}

.submitButton,
.submitButton:disabled:hover {
    background-color: var(--blue);
    color: white;
    border-style: none;
    border-radius: 3px;
    transition: 0.5s;
    padding: 12px;
    width: 100%;
    box-sizing: border-box
}

.submitButton:hover {
    background-color: var(--blueHover);
}

.multi textarea {
    height: 150px;
    resize: none;
    width: 100%;
}

.message {
    visibility: collapse;
    display: flex;
    justify-content: center;
    color: white;
    text-align: center;
    border-radius: 3px;
    padding: 8px;
    margin: 8px;
    width: stretch;
    justify-self: center;
}

.red {
    visibility: visible;
    display: flex;
    justify-self: center;
    background-color: var(--blue);
}

.green {
    visibility: visibility;
    display: flex;
    justify-self: center;
    background-color: var(--red);
}

label {
    color: var(--darkGray);
    display: block;
}


@media screen and (max-width: 600px) {
    .singles div {
        width: 100%;
        display: block;
    }

    .singles {
        display: block;
    }

    .form {
        width: 85vw;
    }

    .formSidebar {
        width: 75px;
    }

    .formMain {
        width: 75%;
    }

    .links {
        padding: 0;
        margin: 0;
    }

    .formSidebar p {
        display: none;
    }

    .formHead img {
        display: none;
    }

}