.workDetailContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.projectDetail {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.imageGallery {
    display: flex;
    align-items: center;
    padding: 0 20px;
    overflow-x: scroll;
}

.projectDetail span {
    transition: ease-in-out 0.2s;
}

.mainLink:hover,
.secondLink:hover,
.prototypeLink:hover {
    color: var(--blue);
}

.mainLink:hover>span,
.prototypeLink:hover>span {
    letter-spacing: 2px;
    margin-left: 8px;
}

#storyboardImage {
    width: 100%;
}

#personasImage {
    width: 75%;
}

#prototypeImage {
    height: 75vh;
    object-fit: contain;
}

.notFound {
    display: flex;
    justify-content: center;
}

.notFound a {
    text-decoration: none;
    border: solid 2px var(--blue);
    border-radius: 3px;
}

@media screen and (max-width:500px) {
    .mainLink {
        font-size: 20pt;
    }
}