.project a {
    text-decoration: none;
}

.projectDetail img {
    margin: 20px;
    min-width: 30vw;
}

.workProjectCard {
    padding: 25px;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: var(--lowContrast);
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.workProjectHeader {
    display: flex;
    justify-content: space-between;
}

.workProjects h3,
h5 {
    margin: 2px;
}

.personalProjects h3,
h5 {
    margin: 2px;
}

.projectThumbnail {
    width: 50px;
    height: 50px;
}

.projectLink {
    text-decoration: none;
    color: black;
}