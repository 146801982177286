.footerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--blueHover);
    height: 150px;
}

.footerContainer p {
    color: rgba(245, 245, 245, 0.76);
    font-size: 10pt;
}

.footerLinks {
    display: flex;
}

.footerLinks a {
    background-color: whitesmoke;
    color: var(--blue);
    padding: 5px;
    margin: 10px;
    text-decoration: none;
    border-radius: 50%;
}