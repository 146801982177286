.logo img {
    width: 50px;
    margin-top: 4px;
}

.navLink,
.navLink:visited {
    color: var(--darkGray);
    text-decoration: none;
    font-family: 'Linden Hill', serif;
    padding: 5px;
    transition: ease-in-out 0.3s;
}

.navLink:hover {
    color: var(--blue);
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ;
    background-color: transparent;
    border-radius: 8px;
    padding: 10px;
    margin: 10px;
    width: 60%;
}

h4 {
    text-align: left;
    color: var(--orange);
    font-size: 18pt;
    margin-top: 5px;
}

.main {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.active {
    text-decoration: underline;
    text-decoration-style: solid;
    color: var(--darkGray);
    font-family: 'Linden Hill', serif;
    padding: 5px;
    transition: ease-in-out 0.3s;
}

.menu {
    display: none;
}

.menu img {
    width: 40px;
}

.miniNav {
    display: none;
    z-index: 2;
    position: absolute;
    top: -100px;
    right: 0;
}

.navButton {
    color: var(--darkGray);
    text-decoration: none;
    margin-right: 8px;
    margin-bottom: 2px;
    border-radius: 5px;
    border-style: none;
    padding: 8px 10px 8px 10px;
    font-size: 12px;

    transition: ease-in-out 0.2s;
}

.navButton:hover {
    transform: scale(1.06);
}

.mainNavContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hamburgerMenuContainer {
    background-color: transparent;
    border-style: none;
}

@media screen and (max-width: 500px) {
    .navContainer {
        display: none;
    }

    .menu {
        display: flex;
    }

    .nav {
        width: 85%;
    }
}